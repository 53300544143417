import Logo from '../images/logo.svg';
import Slider1 from '../images/askidaCorba/slider/image-1.webp';
import Slider2 from '../images/askidaCorba/slider/image-2.webp';
import Slider3 from '../images/askidaCorba/slider/image-3.webp';
import Slider4 from '../images/askidaCorba/slider/image-4.webp';
import Slider5 from '../images/askidaCorba/slider/image-5.webp';
import Slider6 from '../images/askidaCorba/slider/image-6.webp';
import Slider7 from '../images/askidaCorba/slider/image-7.webp';
import soup from '../images/askidaCorba/donation/soup.png';
import success from '../images/askidaCorba/donation/success.png';
import error from '../images/askidaCorba/donation/error.png';

const API_ENDPOINTS = {
    GET_START_OF_DAY_DONATIONS: "/api/Soups/StartOfDayDonationList",
    GET_START_OF_WEEK_DONATIONS: "/api/Soups/StartOfWeekDonationList",
    GET_START_OF_MONTH_DONATIONS: "/api/Soups/StartOfMonthDonationList",
    GET_TOTAL_DONATIONS: "/api/Soups/TotalDonationList",
    GET_TOP_DONATIONS: "/api/Soups/TopDonations?take=",
    GET_LAST_DONATIONS: "/api/Soups/LastDonations?take=",
    GET_DISTRIBUTE_DONATIONS: "/api/Soups/DistrubuteDonations?take=",
    GET_STOCK_HISTORIES: "/api/Soups/StockHistories",
    GET_SOUP_PRICE: "/api/Soups/SoupPrice",
    UPDATE_SOUP: "/api/Soups/Update",
    CREATE_CONTACT: "/api/Contacts/Create",
    UPDATE_CONTACT: "/api/Contacts/Update",
    EDIT_CONTACT: "/api/Contacts/Edit",
    GET_CONTACT: "/api/Contacts/Contact?contactId=",
    Signin: "/api/Signin/Signin",
    CREATE_ORDER : "/api/Orders/Create",
    GET_STOCK_REPORTS : "/api/Soups/StockReports",
}

const IMAGE_ENDPOINTS = {
    LOGO : Logo,
    SLIDER : {
        Slider1 : Slider1,
        Slider2 : Slider2,
        Slider3 : Slider3,
        Slider4 : Slider4,
        Slider5 : Slider5,
        Slider6 : Slider6,
        Slider7 : Slider7,
    },
    DONATION : {
        Soup : soup,
        Success : success,
        Error : error,
    },
}

export { API_ENDPOINTS,IMAGE_ENDPOINTS }