import React from 'react'
import { Link } from 'react-router-dom'
import LoginHelpers from '../../helpers/LoginHelpers';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import './style.css'
const HeaderTopbar = () => {
    return (
        <div className="topbar">
            <div className="container">
                <div className="row">
                    <div className="col col-md-6 col-sm-12 col-12">
                        <div className="contact-intro">
                            <ul>
                                <li><i className="fi flaticon-call"></i><a href="tel:+90 533 407 58 85">0533 407 58 85</a></li>
                                <li><i className="fi flaticon-envelope"></i> info@askidacorba.org</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col col-md-6 col-sm-12 col-12">
                        <div className="contact-info">
                            <ul>
                                {/* <li>
                                    <Grid className="loginWithSocial">
                                        <Button><a href="https://www.facebook.com/pages/category/Charity-Organization/Ask%C4%B1da-%C3%87orba-101122171503772/" target="_blank"><i className="ti-facebook"></i></a></Button>
                                        <Button><a href="https://twitter.com/askida_corba" target="_blank"><i className="ti-twitter-alt"></i></a></Button>
                                        <Button><a href="https://www.instagram.com/askida_corba/?igshid=1hr8ff3252shy" target="_blank"><i className="ti-instagram"></i></a></Button>
                                    </Grid>
                                </li> */}
                                <li><a href="https://www.facebook.com/pages/category/Charity-Organization/Ask%C4%B1da-%C3%87orba-101122171503772/" target="_blank"><i className="ti-facebook"></i></a></li>
                                {/* <li><a href="https://twitter.com/askida_corba" target="_blank"><i className="ti-twitter-alt"></i></a></li> */}
                                <li><a href="https://www.instagram.com/askida_corba/?igshid=1hr8ff3252shy" target="_blank"><i className="ti-instagram"></i></a></li>
                                {/* <li><Link to="/login">Login</Link></li>
                                <li><Link to="/signup">Sign Up</Link></li> */}
                                <li><Link className="theme-btn" to="/donate">Askıya Çorba Bırak</Link></li>
                                {
                                    LoginHelpers.getUser().userName && LoginHelpers.getUser().password ? (
                                        <li><Link className="theme-btn" to="/dashboard" style={{ marginLeft: 10 }}>Yönetici Paneli</Link></li>
                                    ) : null
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderTopbar;