import React, { Component } from 'react';
import { Button } from 'reactstrap';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import axios from 'axios';
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import LoginHelpers from '../../helpers/LoginHelpers';
import {API_ENDPOINTS} from '../../models/Constants';
class LoginPage extends Component {
    state = {
        userName: '',
        password: ''
    }

    onChangeHandler = event => {
        let object = {};
        object[event.target.name] = event.target.value;
        this.setState(object);
    }

    onClickHandler = () => {
        const { userName, password } = this.state;
        if (userName === "" && password === "") {
            toast.error('Kullanıcı adı ve Şifre Giriniz');
            return;
        }

        axios
            .post(API_ENDPOINTS.Signin, {
                userName: userName,
                password: password,
            })
            .then(response => {
                localStorage.setItem("askida_user_id", userName);
                localStorage.setItem("askida_password", password);
                this.props.history.push('/dashboard');
            })
            .catch(err => {
                if (err && err.response) {
                    toast.error('Hata Oluştu');
                }
            });
    }

    componentDidMount() {
        const user = LoginHelpers.getUser();
        if (user.userName && user.password) {
            this.props.history.push('/dashboard');
        }
    }

    render() {
        const { userName, password } = this.state;
        return (
            <Grid className="loginWrapper">
                <Grid className="loginForm">
                    <h2>Kullanıcı Girişi</h2>
                    <form>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    className="inputOutline"
                                    fullWidth
                                    placeholder="Kullanıcı Adı"
                                    value={userName}
                                    variant="outlined"
                                    name="userName"
                                    label="Kullanıcı Adı"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    // onBlur={(e) => changeHandler(e)}
                                    onChange={this.onChangeHandler}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    className="inputOutline"
                                    fullWidth
                                    placeholder="Şifre"
                                    value={password}
                                    variant="outlined"
                                    name="password"
                                    type="password"
                                    label="Şifre"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={this.onChangeHandler}
                                    // onBlur={(e) => changeHandler(e)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid className="formFooter">
                                    <Button outline color="secondary" onClick={() => this.onClickHandler()}>Giriş</Button>
                                    {/* <Button fullWidth className="cBtnTheme" type="submit" >Giriş</Button> */}
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        )
    }
}

export default withRouter(LoginPage);