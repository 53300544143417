import React, { Component } from 'react';
import axios from 'axios';
import { Table } from 'reactstrap'
import { API_ENDPOINTS, IMAGE_ENDPOINTS } from '../../models/Constants';
class StartOfMonthDonations extends Component {
    _isMounted = false;
    state = {
        released: 0, //Askıya Bırakılan
        distributed: 0, //Askıdan Dağıtılan
    }

    fetchData = () => {
        if (!this._isMounted) {
            return;
        }

        axios
            .get(API_ENDPOINTS.GET_START_OF_MONTH_DONATIONS)
            .then(response => {
                this.setState({
                    released: response.data.released,
                    distributed: response.data.distributed
                })
            })
            .catch(err => {

            });
    }

    componentDidMount() {
        this._isMounted = true;
        this.fetchData();
    }
    componentWillUnmount = () => {
        this._isMounted = false;
    };

    render() {
        const { released, distributed } = this.state;
        return (
            <div className="col-lg-3 col-md-6 col-sm-12 col-12 custom-grid">
                <div className="wpo-event-item">
                    <div className="wpo-event-img">
                        <img src={IMAGE_ENDPOINTS.DONATION.Soup} alt="" />
                        {/* <img src={askidaCorba} alt="" /> */}
                        <div className="thumb-text">
                            <span>Bu Ay</span>
                        </div>
                    </div>
                    <div className="wpo-event-text">
                        {/* <h2 style={{ color: "orange" }}>Bu Ay</h2> */}
                        <Table>
                            <thead>
                                <tr>
                                    <td>Askıya Bırakılan</td>
                                    <td>{released}</td>
                                </tr>
                                <tr>
                                    <td>Askıdan Dağıtılan</td>
                                    <td>{distributed}</td>
                                </tr>
                            </thead>
                        </Table>
                    </div>
                </div>
            </div>
        )
    }
}

export default StartOfMonthDonations;