import React, { Fragment, Component } from 'react';
import Header from '../../components/header';
// import PageTitle from '../../components/pagetitle'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import StartOfDayDonations from './startOfDayDonations';
import StartOfWeekDonations from './startOfWeekDonations';
import StartOfMonthDonations from './startOfMonthDonations';
import TotalDonations from './totalDonations';
import TopDonations from './topDonations';
import LastDonations from './lastDonations';
import LastDistrubutes from './lastDistrubutes';
class DonationStatus extends Component {
    render() {

        return (
            <div className="wpo-event-area wpo-event-area2 section-padding">
                <div className="container">
                    <div className="row">
                        <StartOfDayDonations />
                        <StartOfWeekDonations />
                        <StartOfMonthDonations />
                        <TotalDonations />
                    </div>
                    <div className="wpo-blog-sidebar">
                        <div className="row">
                            <TopDonations />
                            <LastDonations />
                            <LastDistrubutes />
                            </div>
                    </div>
                </div>
            </div>
        )
    }
}

const Index = () => {
    return (
        <Fragment>
            <Header />
            {/* <PageTitle pageTitle={'Askı Durumu'} pagesub={'Askı'} /> */}
            <DonationStatus />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
}

export default Index;