import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { toast } from "react-toastify";
import { FormText } from 'reactstrap';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import './style.css'
import { API_ENDPOINTS } from '../../../models/Constants';
import { DonationTabs } from '../../../models/Common';

const GreenCheckbox = withStyles({
    root: {
        color: "gray",
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const DonatePage = (props) => {
    const { selectedTab } = props;
    const [soupPrice, setSoupPrice] = useState(0);
    const [quantity, setQuantity] = useState(1);
    // const [soupPrice,setSoupPrice] = useState(0);
    const [description, setDescription] = useState('');
    const [senderNameSurname, setSenderNameSurname] = useState('');
    const [senderEmail, setSenderEmail] = useState('');
    const [recipientNameSurname, setRecipientNameSurname] = useState('');
    const [recipientEmail, setRecipientEmail] = useState('');
    const [hideDonatorsDetails, setHideDonatorsDetails] = useState(false);
    const [iFrameSrc, setIFrameSrc] = useState(null);

    useEffect(() => {
        getSoupPrice();
    }, []);

    const submitHandler = () => {
        if (soupPrice > 0) {
            if (quantity < 0) {
                toast.error("Lütfen miktar giriniz");
                return;
            }

            if (selectedTab === DonationTabs.Gift) {
                if (!senderNameSurname) {
                    toast.error("Lütfen adınızı ve soyadınızı giriniz");
                    return;
                }
                if (!recipientNameSurname) {
                    toast.error("Lütfen alıcının adını ve soyadını giriniz");
                    return;
                }
                if (!recipientEmail) {
                    toast.error("Lütfen alıcının email adresini giriniz");
                    return;
                }
                if (!description) {
                    toast.error("Lütfen açıklama giriniz");
                    return;
                }
            }
            axios
                .post(API_ENDPOINTS.CREATE_ORDER, {
                    id: undefined,
                    soupPrice: soupPrice,
                    quantity: quantity,
                    description: hideDonatorsDetails ? "Detay Bilgisi Gizli" : description,
                    senderNameSurname: senderNameSurname,
                    senderEmail: senderEmail,
                    recipientNameSurname: recipientNameSurname,
                    recipientEmail: recipientEmail,
                })
                .then(response => {
                    setIFrameSrc(response.data);
                })
                .catch(err => {
                    if (err && err.response) {
                        toast.error(err.response.data);
                    }
                })
        }
    }

    const getSoupPrice = () => {
        axios.get(API_ENDPOINTS.GET_SOUP_PRICE).then(response => {
            setSoupPrice(response.data);
        }).catch(err => {

        });
    }
    return iFrameSrc === null ? (
        <div className="event-contact">
            <div className="wpo-donations-details">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 form-group">
                        <label>Adet Giriniz (Bir Çorba Fiyatı {soupPrice} TL dir)</label>
                        <input
                            name="quantity"
                            type="number"
                            className="form-control"
                            value={quantity}
                            min={1}
                            max={99999}
                            onChange={(event) => setQuantity(parseInt(event.target.value))} />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6 form-group">
                        <label>Gönderici Adı Soyadı</label>
                        <input
                            name="senderNameSurname"
                            className="form-control"
                            value={senderNameSurname}
                            placeholder={selectedTab === DonationTabs.Donate ? "Opsiyonel" : "Zorunlu"}
                            onChange={(event) => setSenderNameSurname(event.target.value)} />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6 form-group">
                        <label>Gönderici Email Adresi</label>
                        <input
                            name="senderEmail"
                            type="email"
                            className="form-control"
                            value={senderEmail}
                            placeholder={"Opsiyonel"}
                            onChange={(event) => setSenderEmail(event.target.value)} />
                    </div>
                    {
                        selectedTab === DonationTabs.Gift && (
                            <>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-6 form-group">
                                    <label>Alıcı Adı Soyadı</label>
                                    <input
                                        name="recipientNameSurname"
                                        className="form-control"
                                        value={recipientNameSurname}
                                        placeholder={"Zorunlu"}
                                        onChange={(event) => setRecipientNameSurname(event.target.value)} />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-6 form-group">
                                    <label>Alıcı Email Adresi</label>
                                    <input
                                        name="recipientEmail"
                                        type="email"
                                        className="form-control"
                                        value={recipientEmail}
                                        placeholder={"Zorunlu"}
                                        onChange={(event) => setRecipientEmail(event.target.value)} />
                                    <FormText invalid>Sizin adınıza bir mail gönderilecektir</FormText>
                                </div>
                            </>
                        )
                    }
                    <div className={selectedTab === DonationTabs.Donate ? "col-lg-8 col-8 form-group" : "col-lg-12 col-12 form-group"}>
                        <label>Açıklama</label>
                        <textarea
                            name="description"
                            className="form-control"
                            value={description}
                            placeholder='Örneğin: "Abdullah Ademoğlu Adına Yemek Ödemesi"'
                            onChange={(event) => setDescription(event.target.value)}></textarea>
                    </div>
                    {
                        selectedTab === DonationTabs.Donate && (
                            <div className="col-lg-4 col-4 form-group">
                                <label style={{ color: "transparent" }}>Detay</label>
                                <FormControlLabel
                                    control={<GreenCheckbox name="hideDonatorsDetails" checked={hideDonatorsDetails} onChange={(event) => setHideDonatorsDetails(!hideDonatorsDetails)} />}
                                    label="Detaylar Gizlensin"
                                />
                            </div>
                        )
                    }
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 form-group">
                        <code>
                            <span style={{ fontSize: 20 }}>Ödenecek Tutar : {quantity * soupPrice} TL</span>
                        </code>
                    </div>
                </div>
                <div className="submit-area">
                    <button type="submit" className="theme-btn submit-btn" onClick={() => submitHandler()}>Askıya Çorba Bırak</button>
                </div>
            </div>
        </div>
    ) : (
        <iframe frameBorder={0} scrolling="no" width="100%" height="1000px" src={iFrameSrc} />
    )
}

export default DonatePage;