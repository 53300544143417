import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const Loading = (props) => {
    return (
        <Spinner animation="border" variant="warning">
        <span className="visually-hidden"></span>
      </Spinner>
    )
}

export default Loading;