import React, { Fragment } from 'react';
import Header from '../../components/header'
// import PageTitle from '../../components/pagetitle'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
// import { Card, CardBody } from 'reactstrap';
import './style.css';


const Index = () => {
    return (
        <Fragment>
            <Header />
            <div className="wpo-about-area section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 colsm-12">
                            <div className="wpo-about-text">
                                <div className="wpo-section-title">
                                    <span>Biz Kimiz?</span>
                                </div>
                                <div className="wpo-blog-sidebar">
                                    <div className="widget category-widget">
                                        <ul>
                                            <li><b>Askıda Çorba</b> Ticari amaçlı kurulmuş bir lokanta değildir; modern bir aşevi olarak kendini nitelendiren yüzlerce gönüllü ve destekleyici iyiliksever tarafından ihtiyaç sahibi herkese herhangi bir ayrım yapmaksızın yemek ikramı sağlayan ve lokanta içerisindeki kazancını işletme masrafları çıktıktan sonra yine ihtiyaç sahiplerine dağıtan ve buram buram iyilik tüten bir yardımlaşma organizasyonudur.</li>
                                            <li>Gün içinde hem müşteriler hem de ihtiyaç sahipleri lokantamıza misafir olurlar. Gönüllü ve destekleyicilerimizin katkılarıyla ihtiyaç sahipleri, pişirilmiş sıcacık yemeklerden yararlanırlar.</li>
                                            <li>Böylece veren el ve alan el maddi ve manevi doygunlukla lokantamızdan ayrılırlar.</li>
                                            <li>Peki lokantamıza ulaşamayan, misafir edemediğimiz ihtiyaç sahiplerini unuttuk mu? Elbette hayır.<br/>
                                                Haftanın belli günlerinde belirlenen lokasyonlarda ya da kapı kapı gezerek ihtiyaç sahiplerine yine gönüllü ve destekçilerimizin katkılarıyla pişirilmiş yemeklerimizi ulaştırmaya çalışıyoruz.</li>
                                            <li>Bir lokma ekmeğin ve bir yudum suyun kıymetini çok iyi bilen bizler, lokantamızdaki hiçbir lokmayı israf etmiyoruz. Lokantamızda artan yemekleri sokakta yaşayan hayvanlara ya da hayvan barınaklarına ulaştırıyoruz.</li>
                                            <li>ASKIDA ÇORBA olarak inanıyoruz ki iyilik bulaşıcıdır. İyilikle ve sevgiyle kaynayan çorbamızda, yemeğimizde “Benim de tuzum olsun” diyen herkesin desteğiyle bu iyilik hareketi hızla büyüyecektir.
Birlikte dokunacak çok yürek, alacak çok yolumuz var…
</li>
                                        </ul>
                                        <h2 style={{ color: "#666666" }}>Haydi Katıl,iyilik büyüsün</h2>
                                       </div>
                                </div>
                                </div>
                        </div>
                         </div>
                </div>
            </div >
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default Index;
