import React, { useState, Fragment } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import DonatePage from './donatePage';
import Header from '../../../components/header'
import Footer from '../../../components/footer'
import Scrollbar from '../../../components/scrollbar'
import classnames from 'classnames';
import { DonationTabs } from '../../../models/Common';

const Index = () => {
    return (
        <Fragment>
            <Header />
            <Donate />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

const Donate = (props) => {
    const [activeTab, setActiveTab] = useState(DonationTabs.Donate);

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    return (
        <div className="wpo-event-details-area section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-12">
                        <div className="wpo-event-item">
                            <div className="wpo-event-details-text">
                                <h2 style={{ color: "#666666" }} className='text-center'>Askıya Çorba Bırak</h2>
                                <blockquote>
                                    Askıya bırakılan çorbalarınızın dağıtımı ihtiyaç sahiplerine yapılmaktadır. Dağıtımlar web sitemize bırakılan sıralamaya göre yapılmakta olup, dağıtım bilgileri de
                                    <a href="https://www.instagram.com/askida_corba/" style={{ color: "gray" }} target="_blank"><b> instagram</b></a> hesabımızda paylaşılmaktadır.
                                    Dağıtımınızın durumunu ve görsellerine ulaşmak isterseniz bize <a href="tel:+90 533 407 58 85">0533 407 58 85</a> numarasından ulaşabilirsiniz.
                                </blockquote>
                            </div>
                            <div className="wpo-event-details-wrap">
                                <div className="wpo-event-details-tab">
                                    <Nav tabs>
                                        <NavItem>
                                            <NavLink className={classnames({ active: activeTab === DonationTabs.Donate })} onClick={() => { toggle(DonationTabs.Donate); }}                                            >
                                                Kendi Adıma
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={classnames({ active: activeTab === DonationTabs.Gift })} onClick={() => { toggle(DonationTabs.Gift); }}                                            >
                                                Hediye
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </div>
                                <div className="wpo-event-details-content">
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId={DonationTabs.Donate}>
                                            <DonatePage selectedTab={activeTab}/>
                                        </TabPane>
                                        <TabPane tabId={DonationTabs.Gift}>
                                            <DonatePage selectedTab={activeTab}/>
                                        </TabPane>
                                    </TabContent>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Index;
