import React, { Component } from 'react';
import { Modal, ModalHeader, Card, CardBody, CardFooter, Form, Label, FormGroup, Input, Row, Col, Button } from 'reactstrap';
import axios from 'axios';
import { API_ENDPOINTS } from '../../models/Constants'
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import moment from 'moment';
import LoginHelpers from '../../helpers/LoginHelpers';
import ReactDateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
class StockOperationModal extends Component {
    _isMounted = false;
    state = {
        id: undefined,
        description: '',
        quantity: 0,
        soupPrice: 0,
        hideDonatorsDetails: false,
        userName: LoginHelpers.getUser().userName,
        date: moment(),
    }

    componentDidMount() {
        this._isMounted = true;
        this.fetchData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    modalHandleCancel = () => {
        this.props.history.push('/dashboard');
    };

    onChangeHandler = event => {
        let object = {};
        object[event.target.name] = event.target.value;
        this.setState(object);
    }

    fetchData = () => {
        if (!this._isMounted) {
            return;
        }

        axios
            .get(API_ENDPOINTS.GET_SOUP_PRICE)
            .then(response => {
                this.setState({
                    soupPrice: response.data
                })
            })
            .catch(err => {
                toast.error('Hata Oluştu');
            });

        const contactId = this.props.contactId;
        axios
            .get(API_ENDPOINTS.GET_CONTACT + contactId)
            .then(response => {
                this.setState({
                    id: response.data.id,
                    description: response.data.description,
                    quantity: response.data.quantity < 0 ? -response.data.quantity : response.data.quantity,
                    totalPrice: response.data.totalPrice,
                    hideDonatorsDetails: response.data.hideDonatorsDetails,
                    date: moment(response.data.date)
                })
            })
            .catch(err => {

            });
    }

    submitData = () => {
        const { id, quantity, description, soupPrice, userName, hideDonatorsDetails, date } = this.state;
        if (soupPrice <= 0) {
            toast.error("Lütfen Çorba Fiyatını Giriniz");
            return;
        }
        if (quantity === 0) {
            toast.error("Lütfen Miktarı Giriniz");
            return;
        }

        axios
            .post(API_ENDPOINTS.EDIT_CONTACT, {
                id: id,
                description: description,
                quantity: parseFloat(quantity),
                hideDonatorsDetails: hideDonatorsDetails,
                userName: userName,
                date: date,
            })
            .then(response => {
                this.props.history.push("/donation-status");
            })
            .catch(err => {
                if (err && err.response) {
                    toast.error(err.response.data);
                }
            });
    }

    render() {
        const { soupPrice, quantity, description, hideDonatorsDetails, date } = this.state;
        return (
            <Modal isOpen={true} toggle={this.modalHandleCancel} size="lg" style={{ width: '1500px', maxWidth: '1500px' }}>
                <ModalHeader>{description} - İşlemini Düzenle</ModalHeader>
                <div className="modal-header">
                    <button aria-hidden={true} className="close" data-dismiss="modal" type="button"
                        onClick={this.modalHandleCancel}>
                        <i className="nc-icon nc-simple-remove" />
                    </button>
                </div>
                <div className="modal-body">
                    <div>
                        <Card>
                            <CardBody>
                                <Form action="" method="">
                                    <Label>Adet Giriniz (Bir Çorba Fiyatı {soupPrice} TL dir)</Label>
                                    <FormGroup>
                                        <Input name="quantity" type="number" autoComplete="off" value={quantity} min={1} onChange={this.onChangeHandler} placeholder="Adet Giriniz" />
                                    </FormGroup>
                                    <Row>
                                        <Col lg="10" md="10" sm="10">
                                            <Label>Açıklama *</Label>
                                            <FormGroup>
                                                <Input name="description" value={description} onChange={this.onChangeHandler} placeholder='Örneğin: "Abdullah Ademoğlu Adına Yemek Ödemesi"' />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="2" md="2" sm="2">
                                            <Label></Label>
                                            <FormGroup check style={{ marginTop: 10 }}>
                                                <Label check>
                                                    <Input name="hideDonatorsDetails" onChange={(event) => this.setState({ hideDonatorsDetails: event.target.checked })} checked={hideDonatorsDetails} type="checkbox" />
                                                    <span className="form-check-sign" />Detaylar Gizlensin
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Label>Tarih</Label>
                                    <FormGroup>
                                        <ReactDateTime
                                            inputProps={{
                                                className: "form-control",
                                                placeholder: "Date Picker Here"
                                            }}
                                            value={date.toDate()}
                                            dateFormat="YYYY-MM-DD"
                                            timeFormat={false}
                                            closeOnSelect
                                            onChange={value => { this.setState({ date: moment(value) }) }}
                                        />
                                    </FormGroup>
                                </Form>
                            </CardBody>
                            <CardFooter>
                                <div className="submit-area">
                                    <button type="submit" className="theme-btn submit-btn" onClick={() => this.submitData()}>Kaydet</button>&nbsp;&nbsp;&nbsp;
                                    <button type="submit" className="theme-btn submit-btn" onClick={this.modalHandleCancel}>Kapat</button>
                                </div>
                            </CardFooter>
                        </Card>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default withRouter(StockOperationModal)