import moment from 'moment';

class GlobalHelperClass {
    formatDate = (dateObj, format) => {
        if (typeof dateObj === "string") {
            let dt = moment(dateObj);
            if (!dt._isUTC) {
                dt = moment.utc(dateObj);
            }
            return dt._isValid ?
                format ? dt.format(format) : dt.format('YYYY-MM-DD HH:mm:ss')
                // format ? dt.local().format(format) : dt.local().format('YYYY-MM-DD HH:mm:ss')
                : 'INVALID DATE'
        }

        if (typeof dateObj === "object") {
            let dt = moment(dateObj);
            return dt._isValid ?
                format ? dt.format(format) : dt.format('YYYY-MM-DD HH:mm:ss')
                // format ? dt.local().format(format) : dt.local().format('YYYY-MM-DD HH:mm:ss')
                : 'INVALID DATE'
        }
    }

}

const GlobalHelpers = new GlobalHelperClass();
export default GlobalHelpers;
