class LoginHelperClass {
    getUser = () => {
        try {
            const userName = localStorage.getItem('askida_user_id');
            const password = localStorage.getItem('askida_password');
            return {userName: userName, password: password};
        } catch (err) {
            return {userName: "", password: ""};
        }
    };
    clearUser = () => {
        localStorage.removeItem('askida_user_id');
        localStorage.removeItem('askida_password');;
    };
    isLoggedIn = () => {
        const userName = localStorage.getItem('askida_user_id');
        const password = localStorage.getItem('askida_password');
        return !!(userName && userName.length > 0 && password && password.length > 0);
    };
}

const LoginHelpers = new LoginHelperClass();
export default LoginHelpers;
