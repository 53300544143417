import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

/* #region  Not-used Routing */
// import Homepage2 from '../HomePage2'
// import Homepage3 from '../HomePage3'
// import CasePage from '../CasePage'
// import CaseSinglePage from '../CaseSinglePage'
// import EventPageSingle from '../EventPageSingle'
// import VolunteerPage from '../volunteerPage'
// import BlogPage from '../BlogPage'
// import BlogPageLeft from '../BlogPageLeft'
// import BlogPageFullwidth from '../BlogPageFullwidth'
// import BlogDetails from '../BlogDetails'
// import BlogDetailsLeftSiide from '../BlogDetailsLeftSiide'
// import BlogDetailsFull from '../BlogDetailsFull'
// import LoginPage from '../LoginPage' 
// import SignUpPage from '../SignUpPage'
// import ForgotPassword from '../ForgotPassword'
/* #endregion */

/* #region  Version 1 - Routing */
// import Homepage from '../HomePage'
// import AboutPage from '../AboutPage'
// import EventPage from '../EventPage'
// import EventPageSingle from '../EventPageSingle'
// import ContactPage from '../ContactPage'
// import DonatePage from '../DonatePage'
// import ErrorPage from '../ErrorPage'
// import LoginPage from '../LoginPage/index2'
// import DonationStatusPage from '../DonationStatusPage'
// import PaymentSuccessPage from '../DonatePage/payment-success'
// import PaymentErrorPage from '../DonatePage/payment-error'
// import DistantSalesContractPage from '../Contracts/distantSalesContract'
// import PreliminaryInformationFormPage from '../Contracts/preliminaryInformationForm'
// import AdminPage from '../AdminPage';
// import FrequentlyAskedQuestionsPage from '../FrequentlyAskedQuestionsPage';
/* #endregion */

/* #region Version 2 - Routing  */
import Home from './Home/home';
import Login from './Login/login';
import Event from './Events/event';
import EventDetail from './Events/eventDetail';
import About from './About/about';
import FrequentlyAskedQuestions from './About/frequentlyAskedQuestions';
import Contact from './Contact/contact';
import Donate from './Donate/v2/index';
//import Donate from './Donate/donate';
import DonationStatus from './Donate/donationStatus';
import PaymentSuccess from './Donate/paymentSuccess';
import PaymentError from './Donate/paymentError';
import DistantSalesContract from './Contracts/distantSalesContract';
import PreliminaryInformation from './Contracts/preliminaryInformation';
import Dashboard from './Reporter/dashboard';
import Error from './Common/error';
/* #endregion */

const AllRoute = () => {

    return (
        <div className="App">
            <Router>
                <Switch>
                    <Route exact path='/' component={Home} />
                    <Route path='/home' component={Home} />
                    <Route path='/events' component={Event} />
                    <Route path='/event-details/:eventId' component={EventDetail} />
                    <Route path='/donate' component={Donate} />
                    <Route path='/contact' component={Contact} />
                    <Route path='/404' component={Error} />
                    <Route path='/login' component={Login} />
                    <Route path='/donation-status' component={DonationStatus} />
                    <Route path='/payment-success' component={PaymentSuccess} />
                    <Route path='/payment-error' component={PaymentError} />
                    <Route path='/distant-sales-contract' component={DistantSalesContract} />
                    <Route path='/preliminary-information-form' component={PreliminaryInformation} />
                    <Route path='/dashboard' component={Dashboard} />
                    <Route path='/about' component={About} />
                    <Route path='/frequentlyAskedQuestions' component={FrequentlyAskedQuestions} />

                    {/* Kullanılmayanlar 
                    <Route path='/home2' component={Homepage2} />
                    <Route path='/home3' component={Homepage3} />
                    <Route path='/case' component={CasePage} />
                    <Route path='/case-single' component={CaseSinglePage} />
                    <Route path='/event-details' component={EventPageSingle} />
                    <Route path='/volunteer' component={VolunteerPage} />
                    <Route path='/blog' component={BlogPage} />
                    <Route path='/blog-left' component={BlogPageLeft} />
                    <Route path='/blog-fullwidth' component={BlogPageFullwidth} />
                    <Route path='/blog-details' component={BlogDetails} />
                    <Route path='/blog-details-left' component={BlogDetailsLeftSiide} />
                    <Route path='/blog-details-fullwidth' component={BlogDetailsFull} />
                    <Route path='/contact' component={ContactPage} />
                    <Route path='/forgot-password' component={ForgotPassword} />
                    <Route path='/signup' component={SignUpPage} />
                    */}
                </Switch>
            </Router>

        </div>
    );
}

export default AllRoute;
