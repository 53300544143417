import React, { Fragment, useState } from 'react';
import Header from '../../components/header'
// import PageTitle from '../../components/pagetitle'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import SoupOperations from './soupOperations';
import StockOperations from './stockOperations';
import StockHistories from './stockHistories';
import StockOperationModal from './stockOperationModal';
import StockReports from './stockReports';
import queryString from 'query-string';
import LoginHelpers from '../../helpers/LoginHelpers';
import { withRouter } from "react-router-dom";
const AdminPage = (props) => {
    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }
    const params = queryString.parse(props.location.search);
    const { contactId } = params;

    const isLogged = (LoginHelpers.getUser().userName && LoginHelpers.getUser().password ? true : false);

    if (!isLogged) {
        props.history.push('/login');
    }

    return (
        <Fragment>
            <Header />
            {/* <PageTitle pageTitle={'Askı Durumu'} pagesub={'Askı'} /> */}
            <div className="wpo-event-area wpo-event-area2 section-padding">
                <div className="container">
                    <Nav tabs>
                        <NavItem>
                            <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { toggle('1'); }}>
                                Çorba İşlemleri
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { toggle('2'); }}>
                                Askı İşlemleri
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({ active: activeTab === '3' })} onClick={() => { toggle('3'); }}>
                                Askı İşlem Geçmişi
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({ active: activeTab === '4' })} onClick={() => { toggle('4'); }}>
                                Rapor İşlemleri
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <Row>
                                <Col sm="12">
                                    <SoupOperations />
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="2">
                            <Row>
                                <Col sm="12">
                                    <StockOperations />
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="3">
                            <Row>
                                <Col sm="12">
                                    <StockHistories />
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="4">
                            <Row>
                                <Col sm="12">
                                    <StockReports/>
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>
                    { contactId > 0 ? <StockOperationModal contactId={contactId} /> : null}
                </div>
            </div>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
}

export default withRouter(AdminPage);