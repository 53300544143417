import React from 'react';
import ContactForm from '../ContactFrom'
import './style.css'

const Contactpage = () => {

    return (
        <section className="wpo-contact-form-map section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="contact-form">
                                    <h2 style={{ color: "#666666" }}>İletişim Bilgilerimiz</h2>
                                    {/* <h2>İletişime Geçin</h2>
                                    <ContactForm /> */}
                                    {/* <div className="info-item"> */}
                                    <p><b>Adres:</b> Erzurum, Dumlupınar Cd. 8 A, 06590 Çankaya/Ankara</p>
                                    <p><b>E-Posta:</b> info@askidacorba.org</p>
                                    <p><b>Telefon:</b> <a href="tel:+90 533 407 58 85">0533 407 58 85</a></p>
                                </div>
                            </div>
                            <div className="col col-lg-6 col-md-6 col-sm-12 col-12">
                                <div class="mapouter">
                                    <div class="gmap_canvas">
                                        <iframe width="600" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=Ask%C4%B1da%20%C3%87orba,%20Erzurum,%20Dumlup%C4%B1nar%20Cd.%208%20A,%2006590%20%C3%87ankaya/Ankara&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default Contactpage;
