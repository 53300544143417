import React, { Fragment } from 'react';
import Header from '../../components/header'
// import PageTitle from '../../components/pagetitle'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import './style.css'


const Index = () => {
    return (
        <Fragment>
            <Header />
            <div className="wpo-about-area section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 colsm-12">
                            <div className="wpo-about-text">
                                <div className="wpo-section-title">
                                    <span>Sık Sorulan Sorular?</span>
                                </div>
                                <div className="wpo-blog-sidebar">
                                    <div className="widget category-widget">
                                        <ul>
                                            <span><u><b>Bir mahallede toplu yemek dağıtmak için neler yapmam gerekiyor?</b></u></span>
                                            <li><p>Bir mahallede toplu dağıtım yapmak için minimum 70 kişilik yemek dağıtımı olması gerekmektedir. Oluşturduğumuz fiyat listemizde dilerseniz menüyü kendiniz seçebileceğiniz gibi dilerseniz bizim oluşturduğumuz menülerden de seçim yapabilirsiniz. Kişi sayısı 70’ten daha az olursa, dağıtmak isteyenlerin yemekleri birleştirilerek dağıtılır.</p></li>
                                            <span><u><b>Askıya Bırakılan Çorbalar Nasıl ve Ne Zaman Dağıtılıyor?</b></u></span>
                                            <li><p>Askıya bıraktığınız çorbalar gün içinde lokantaya gelen ihtiyaç sahibi insanlara ikram edilirken, ayrıca biriken çorbalarımız her ayın ilk haftası menüye çevrilerek Ankara’da belli lokasyonlarda, önceliğimiz öksüz ve yetim aileler olmak üzere, takibimizde olan ihtiyaç sahiplerine dağıtılır.</p></li>
                                            <span><u><b>Destek olduğunuz aileleri nasıl tespit etmektesiniz?</b></u></span>
                                            <li><p>Öncelikle iletişim kanallarımıza (Web/GSM) gelen başvurulardan, ilgili mahallelerde ya da bu mahallelere yakın çevrede bulanan gönüllü arkadaşlarımızın aileleri ziyaret ederek tespit ediliyor.</p></li>
                                            <span><u><b>Sağladığım desteğin takibini nasıl yaparım?</b></u></span>
                                            <li><p>
                                            Askıya bırakılan ve askıdan dağıtılan tüm çorbalar <a href="https://askidacorba.org/donation-status/"><b>askidacorba.org</b></a> sitemizde görünmektedir.
                                            Ayrıca <a href="https://www.instagram.com/askida_corba/"><b>Instagram</b></a> ve <a href="https://www.facebook.com/pages/category/Charity-Organization/Askıda-Çorba-101122171503772/"><b>Facebook </b> </a>
                                            adreslerimizden de güncel dağıtımlara yönelik resim ve videolara ulaşabilirsiniz. Dağıtımların resim ve videolarını özel olarak istemeniz durumunda mail adresinizi lütfen açıklamaya giriniz.
                                            <p><b style={{color : "gray"}}>Bir Ay içinde Askıya Bırakılan çorbaların dağıtım listesi takip eden ayın ilk haftası yapılmaktadır.</b></p>

                                            </p></li>
                                            <span><u><b>Dağıtımlarınıza İsteyen Herkes Katılabiliyor mu?</b></u></span>
                                            <li><p>Askıda Çorba gönüllülük esasıyla çalışan bir hareket olup, gönüllülerden oluşan ekibin emeği ve enerjisi ile varlığını sürdürmektedir. Bu nedenle vereceğiniz gönüllü destekler Askıda Çorba'ya çok değerli katkılar sağlamaktadır. Sizler de dilediğiniz vakit dağıtımlara katılabilirsiniz. Dağıtım duyurularımızdan haberdar olmak için 0 533 407 58 85 numaralı telefonumuza mesaj atarsanız sizi duyuru grubumuza ekleyebiliriz.</p></li>
                                            <h2 style={{ color: "#666666" }}>Ana sayfamızda bulunan “Ekibe Gönüllü Ol” formunu doldurarak sizlerde bu iyilik hareketine destek olabilirsiniz.</h2>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default Index;
