
import React, { Component } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './style.css'

class CaseSlide extends Component {

    state = {
        images: this.props.images
    }
    render() {
        var settings = {
            dots: false,
            arrows: true,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            margin: 10,
            loop: true,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <div className="wpo-case-area section-padding">
                <div className="container">
                    <div className="wpo-case-wrap">
                        <div className="wpo-case-slider">
                            <Slider {...settings}>
                                {
                                    this.state.images ? (
                                        this.state.images.map(row => {
                                            return (
                                                <div className="wpo-case-single">
                                                    <div className="wpo-case-item">
                                                        <div className="wpo-case-img">
                                                            <img src={row} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    ) : ''
                                }
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CaseSlide;




