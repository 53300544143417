import React, { Fragment } from 'react';
import Header from '../../components/header';
import Hero from '../../components/hero';
import CaseSlide from '../../components/case';
// import CounterSection from '../../components/counter'
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import { IMAGE_ENDPOINTS } from '../../models/Constants';

const images = [IMAGE_ENDPOINTS.SLIDER.Slider1, IMAGE_ENDPOINTS.SLIDER.Slider2, IMAGE_ENDPOINTS.SLIDER.Slider3, IMAGE_ENDPOINTS.SLIDER.Slider4, IMAGE_ENDPOINTS.SLIDER.Slider5,IMAGE_ENDPOINTS.SLIDER.Slider6,IMAGE_ENDPOINTS.SLIDER.Slider7]

const Index = () => {
    return (
        <Fragment>
            <Header />
            <Hero />
            <CaseSlide images={images} />
            {/* <CounterSection/> */}
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default Index;