import React from 'react'
import { Link } from 'react-router-dom'
import HeaderTopbar from '../HeaderTopbar'
import MobileMenu from '../../components/MobileMenu'
import LoginHelpers from '../../helpers/LoginHelpers';
import { IMAGE_ENDPOINTS } from '../../models/Constants';
import './style.css'

const style = {
    marginRight : 10,
    marginTop : 42,
    padding : 10
}

const Header = () => {
    // const SubmitHandler = (e) =>{
    //     e.preventDefault()
    //  }
    return (
        <div className="middle-header header-style-3">
            {
                LoginHelpers.getUser().userName && LoginHelpers.getUser().password ? (
                    <HeaderTopbar />
                ) : null
            }
            <div className="container">
                <div className="header-content">
                    <div className="row">
                        <div className="col-lg-2 col-md-4 col-sm-4 col-4">
                            <div className="logo">
                                <Link to="/home" title=""><img src={IMAGE_ENDPOINTS.LOGO} width={100} alt="" /></Link>
                            </div>
                        </div>
                        <div className="col-lg-10 d-lg-block d-none">
                            <nav>
                                <ul >
                                    <li><Link to="/home" className="theme-btn" style={style} >Ana Sayfa</Link></li>
                                    <li><Link to="/donation-status" className="theme-btn" style={style} >Askı Durumu</Link></li>
                                    <li><Link to="/donate" className="theme-btn" style={style} >Askıya Çorba Bırak</Link></li>
                                    <li><Link to="/events" className="theme-btn" style={style} >Etkinliklerimiz</Link></li>
                                    <li><Link to="/about" className="theme-btn" style={style} >Hakkımızda</Link></li>
                                    <li><Link to="/frequentlyAskedQuestions" className="theme-btn" style={style} >Sıkça Sorulan Sorular</Link></li>
                                    <li><Link to="/contact" className="theme-btn" style={style} >İletişim</Link></li>
                                </ul >
                            </nav >
                        </div >
                        <div className="col-lg-0 col-md-6 col-sm-6 col-6">
                           
                        </div>
                        <div className="col-md-2 col-sm-2 col-2">
                            <MobileMenu />
                        </div>
                    </div >

                    <div className="clearfix"></div>
                </div >

            </div >
        </div >
    )

    Header.contextTypes = {
        router: React.PropTypes.object
    }
}

export default Header;