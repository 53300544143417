import React, { Component } from 'react';
import { Card, CardHeader, CardTitle, CardBody, CardFooter, Form, FormGroup, Input, Label, Row, Col } from 'reactstrap';
import axios from 'axios';
import { API_ENDPOINTS } from '../../models/Constants'
import { withRouter} from "react-router-dom";
import {toast} from "react-toastify";
import LoginHelpers from '../../helpers/LoginHelpers';
class StockOperations extends Component {
    _isMounted = false;
    state = {
        quantity: 0,
        description: '',
        soupPrice: 0,
        userName: LoginHelpers.getUser().userName,
        hideDonatorsDetails: false,
    }

    componentDidMount() {
        this._isMounted = true;
        this.fetchData();
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    };

    onChangeHandler = event => {
        let object = {};
        object[event.target.name] = event.target.value;
        this.setState(object);
    }

    fetchData = () => {
        if (!this._isMounted) {
            return;
        }
        axios
            .get(API_ENDPOINTS.GET_SOUP_PRICE)
            .then(response => {
                this.setState({
                    soupPrice: response.data
                })
            })
            .catch(err => {

            });
    }

    submitData = (status) => {
        const { quantity, description, soupPrice, userName, hideDonatorsDetails } = this.state;
        if (soupPrice <= 0) {
            toast.error("Lütfen Çorba Fiyatını Giriniz");
            return;
        }
        if (quantity === 0) {
            toast.error("Lütfen Miktarı Giriniz");
            return;
        }

        if (status) {
            axios
                .post(API_ENDPOINTS.CREATE_CONTACT, {
                    id: undefined,
                    description: description,
                    quantity: parseFloat(quantity),
                    hideDonatorsDetails: hideDonatorsDetails,
                    userName: userName
                })
                .then(response => {
                    this.props.history.push("/donation-status")
                })
                .catch(err => {
                    if (err && err.response) {
                        toast.error('Askıya Çorba Ekleme Sırasında Hata Oluştu');
                    }
                });
        } else {
            axios
                .post(API_ENDPOINTS.UPDATE_CONTACT, {
                    id: undefined,
                    description: description,
                    quantity: parseFloat(quantity),
                    hideDonatorsDetails: hideDonatorsDetails,
                    userName: userName
                })
                .then(response => {
                    this.props.history.push("/donation-status")
                })
                .catch(err => {
                    if (err && err.response) {
                        toast.error('Askıya Çorba Güncelleme Sırasında Hata Oluştu');
                    }
                });
        }
    }

    render() {
        const { soupPrice, quantity, description, hideDonatorsDetails } = this.state;
        return (
            <Card>
                <CardHeader>
                    <CardTitle tag="h4">Askıya Ekle/Güncelle</CardTitle>
                </CardHeader>
                <CardBody>
                    <Form action="" method="">
                        <Label>Adet Giriniz (Bir Çorba Fiyatı {soupPrice} TL dir)</Label>
                        <FormGroup>
                            <Input name="quantity" type="number" autoComplete="off" value={quantity} min={1} onChange={this.onChangeHandler} placeholder="Adet Giriniz" />
                        </FormGroup>
                        <Row>
                            <Col lg="10" md="10" sm="10">
                                <Label>Açıklama *</Label>
                                <FormGroup>
                                    <Input name="description" value={description} onChange={this.onChangeHandler} placeholder='Örneğin: "Abdullah Ademoğlu Adına Yemek Ödemesi"' />
                                </FormGroup>
                            </Col>
                            <Col lg="2" md="2" sm="2">
                                <Label></Label>
                                <FormGroup check style={{ marginTop: 10 }}>
                                    <Label check>
                                        <Input name="hideDonatorsDetails" onChange={(event) => this.setState({ hideDonatorsDetails: event.target.checked })} checked={hideDonatorsDetails} type="checkbox" />
                                        <span className="form-check-sign" />Detaylar Gizlensin
                                            </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </CardBody>
                <CardFooter>
                    <div className="submit-area">
                        <button type="submit" className="theme-btn submit-btn" onClick={() => this.submitData(true)}>Askıya Ekle</button>&nbsp;&nbsp;&nbsp;
                        <button type="submit" className="theme-btn submit-btn" onClick={() => this.submitData(false)}>Askıdan Dağıt</button>&nbsp;&nbsp;&nbsp;
                    </div>
                </CardFooter>
            </Card>
        )
    }
}

export default withRouter(StockOperations)