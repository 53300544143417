import React, { useEffect, useState } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import './style.css'

const Scrollbar = () => {
    const [isRender,setIsRender] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setIsRender(true);
            setTimeout(() => {
                setIsRender(false); 
            }, 3000);
        }, 3000);
    }, [])

    return (
        <div className="col-lg-12">
            <div className="header-menu">
                <ul className="smothscroll">
                    <li><AnchorLink href='#scrool'><i className="fa fa-arrow-up"></i></AnchorLink></li>
                </ul>

                <div id="WhatsAppFooter">
                    <a href="https://wa.me/905334075885?text=Merhaba, Ben bilgi almak istiyorum." target='_blank'>
                        <span className='whatsapp-icon'><i className='fa fa-whatsapp'></i></span>
                        <span className='whatsapp-text' style={ isRender ? { left: '40px', width: '100px' } : { }}>Bize ulaşın!</span>
                    </a>
                </div>
            </div>
        </div>

    )
}

export default Scrollbar;
