import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import CaseSlide from '../../components/case'
import './style.css'
import EventData from '../event/eventData';

const EventSingle = (props) => {
    let eventId = props.eventId;
    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    var event = EventData.find(p => p.id === eventId);

    return (
        <div className="wpo-event-details-area section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 custom-grid">
                    {/* <div className="col col-lg-6 col-md-12 col-sm-12"> */}
                        <div className="wpo-event-item">
                            <div className="wpo-event-img">
                                <img src={event.cover} alt="" width={540} height="auto"/* {auto}  */style={{ maxWidth: 540, maxHeight: 300 }} class="responsive"/>
                                <div className="thumb-text">
                                    <span>{event.date}</span>
                                    <span>{event.mounth}</span>
                                </div>
                            </div>
                            {/* <div className="wpo-event-details-text">
                                <p>On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue and equal blame belongs to those who fail in their duty through weakness of will, which is the same as saying through shrinking from toil and pain.</p>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 custom-grid">
                    {/* <div className="col col-lg-6 col-md-12 col-sm-12"> */}
                        <div className="wpo-event-details-wrap">
                            <div className="wpo-event-details-tab">
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { toggle('1'); }} style={{ color: "rgb(102, 102, 102)" }}>
                                            Etkinlik Hakkında
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </div>
                            <div className="wpo-event-details-content" style={{ minHeight: 250, maxHeight: 250 }}>
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="1" id="Schedule">
                                        <p>{event.title}</p>
                                        {/* <ul>
                                            <li>The wise man therefore in these matters.</li>
                                            <li>In a free hour, when our power of choice and when nothing.</li>
                                            <li>Else he pains to avoid pains.</li>
                                        </ul> */}
                                        <ul>
                                            {
                                                event.description.map(desc => {
                                                    return (<li>{desc}</li>)
                                                })
                                            }
                                        </ul>
                                    </TabPane>
                                </TabContent>
                            </div>
                        </div>
                    </div>
                </div>
                <CaseSlide images={event.images} />
            </div>
        </div>
    );

    // return (
    //     <div className="wpo-event-details-area section-padding">
    //         <div className="container">
    //             <div className="row">
    //                 <div className="col col-lg-6">
    //                     <div className="wpo-event-item">
    //                         <div className="wpo-event-img">
    //                             <img src={img1} alt="" width={400} height={300} />
    //                             <div className="thumb-text">
    //                                 <span>25</span>
    //                                 <span>NOV</span>
    //                             </div>
    //                         </div>
    //                         <div className="wpo-event-details-text">
    //                             <h2>Education for All Children</h2>
    //                             <p>On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue and equal blame belongs to those who fail in their duty through weakness of will, which is the same as saying through shrinking from toil and pain.</p>
    //                         </div>
    //                     </div>
    //                 </div>
    //                 <div className="col col-lg-6">
    //                     <div className="wpo-event-details-wrap">
    //                         <div className="wpo-event-details-tab">
    //                             <Nav tabs>
    //                                 <NavItem>
    //                                     <NavLink
    //                                         className={classnames({ active: activeTab === '1' })}
    //                                         onClick={() => { toggle('1'); }}
    //                                     >
    //                                         Event Schedule
    //                                             </NavLink>
    //                                 </NavItem>
    //                             </Nav>
    //                         </div>
    //                         <div className="wpo-event-details-content">
    //                             <TabContent activeTab={activeTab}>
    //                                 <TabPane tabId="1" id="Schedule">
    //                                     <p>These cases are perfectly simple and easy to distinguish. In a free hour, when our power of choice is untrammelled and when nothing prevents our being able to do what we like best, every pleasure is to be welcomed and every pain avoided.</p>
    //                                     <ul>
    //                                         <li>The wise man therefore in these matters.</li>
    //                                         <li>In a free hour, when our power of choice and when nothing.</li>
    //                                         <li>Else he pains to avoid pains.</li>
    //                                         <li>We denounce with righteous indignation dislike men. </li>
    //                                         <li>Which is the same as saying through.</li>
    //                                         <li>The wise man therefore always holds in these matters.</li>
    //                                         <li>Power of choice and when nothing.</li>
    //                                     </ul>
    //                                 </TabPane>
    //                             </TabContent>

    //                         </div>
    //                     </div>

    //                 </div>
    //                 {/* <CaseSlide sliders={[IMAGE_ENDPOINTS.EVENTS.YirmiUcNisan + "image-1.jpeg"]}/> */}
    //             </div>
    //         </div>
    //     </div>
    // );
}

export default EventSingle;




