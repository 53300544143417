//Hamburger
import HamburgerCard from '../../images/askidaCorba/events/hamburger/card.webp';
import HamburgerCover from '../../images/askidaCorba/events/hamburger/cover.webp';
import HamburgerSlider1 from '../../images/askidaCorba/events/hamburger/1.webp';
import HamburgerSlider2 from '../../images/askidaCorba/events/hamburger/2.webp';
import HamburgerSlider3 from '../../images/askidaCorba/events/hamburger/3.webp';
import HamburgerSlider4 from '../../images/askidaCorba/events/hamburger/4.webp';
import HamburgerSlider5 from '../../images/askidaCorba/events/hamburger/5.webp';

//Yaş Pasta
import YasPastaCard from '../../images/askidaCorba/events/yas-pasta/card.webp';
import YasPastaCover from '../../images/askidaCorba/events/yas-pasta/cover.webp';
import YasPastaSlider1 from '../../images/askidaCorba/events/yas-pasta/1.webp';
import YasPastaSlider2 from '../../images/askidaCorba/events/yas-pasta/2.webp';
import YasPastaSlider3 from '../../images/askidaCorba/events/yas-pasta/3.webp';
import YasPastaSlider4 from '../../images/askidaCorba/events/yas-pasta/4.webp';
import YasPastaSlider5 from '../../images/askidaCorba/events/yas-pasta/5.webp';

//Pide
import PideCard from '../../images/askidaCorba/events/pide/card.webp';
import PideCover from '../../images/askidaCorba/events/pide/cover.webp';
import PideSlider1 from '../../images/askidaCorba/events/pide/1.webp';
import PideSlider2 from '../../images/askidaCorba/events/pide/2.webp';
import PideSlider3 from '../../images/askidaCorba/events/pide/3.webp';
import PideSlider4 from '../../images/askidaCorba/events/pide/4.webp';
import PideSlider5 from '../../images/askidaCorba/events/pide/5.webp';

//Miraç Kandili
import MiracKandiliCard from '../../images/askidaCorba/events/mirac-kandili/card.webp';
import MiracKandiliCover from '../../images/askidaCorba/events/mirac-kandili/cover.webp';
import MiracKandiliSlider1 from '../../images/askidaCorba/events/mirac-kandili/1.webp';
import MiracKandiliSlider2 from '../../images/askidaCorba/events/mirac-kandili/2.webp';
import MiracKandiliSlider3 from '../../images/askidaCorba/events/mirac-kandili/3.webp';
import MiracKandiliSlider4 from '../../images/askidaCorba/events/mirac-kandili/4.webp';
import MiracKandiliSlider5 from '../../images/askidaCorba/events/mirac-kandili/5.webp';

//Berat Kandili
import BeratKandiliCard from '../../images/askidaCorba/events/berat-kandili/card.webp';
import BeratKandiliCover from '../../images/askidaCorba/events/berat-kandili/cover.webp';
import BeratKandiliSlider1 from '../../images/askidaCorba/events/berat-kandili/1.webp';
import BeratKandiliSlider2 from '../../images/askidaCorba/events/berat-kandili/2.webp';
import BeratKandiliSlider3 from '../../images/askidaCorba/events/berat-kandili/3.webp';
import BeratKandiliSlider4 from '../../images/askidaCorba/events/berat-kandili/4.webp';
import BeratKandiliSlider5 from '../../images/askidaCorba/events/berat-kandili/5.webp';

//Ramazan Bereketi
import RamazanBereketiCard from '../../images/askidaCorba/events/ramazan-bereketi/card.webp';
import RamazanBereketiCover from '../../images/askidaCorba/events/ramazan-bereketi/cover.webp';
import RamazanBereketiSlider1 from '../../images/askidaCorba/events/ramazan-bereketi/1.webp';
import RamazanBereketiSlider2 from '../../images/askidaCorba/events/ramazan-bereketi/2.webp';
import RamazanBereketiSlider3 from '../../images/askidaCorba/events/ramazan-bereketi/3.webp';
import RamazanBereketiSlider4 from '../../images/askidaCorba/events/ramazan-bereketi/4.webp';
import RamazanBereketiSlider5 from '../../images/askidaCorba/events/ramazan-bereketi/5.webp';

//Ulusal Eğemenlik ve Çocuk Bayramı
import CocukBayramiCard from '../../images/askidaCorba/events/cocuk-bayrami/card.webp';
import CocukBayramiCover from '../../images/askidaCorba/events/cocuk-bayrami/cover.webp';
import CocukBayramiSlider1 from '../../images/askidaCorba/events/cocuk-bayrami/1.webp';
import CocukBayramiSlider2 from '../../images/askidaCorba/events/cocuk-bayrami/2.webp';
import CocukBayramiSlider3 from '../../images/askidaCorba/events/cocuk-bayrami/3.webp';
import CocukBayramiSlider4 from '../../images/askidaCorba/events/cocuk-bayrami/4.webp';
import CocukBayramiSlider5 from '../../images/askidaCorba/events/cocuk-bayrami/5.webp';

//Kadir Gecesi
import KadirGecesiCard from '../../images/askidaCorba/events/kadir-gecesi/card.webp';
import KadirGecesiCover from '../../images/askidaCorba/events/kadir-gecesi/cover.webp';
import KadirGecesiSlider1 from '../../images/askidaCorba/events/kadir-gecesi/1.webp';
import KadirGecesiSlider2 from '../../images/askidaCorba/events/kadir-gecesi/2.webp';
import KadirGecesiSlider3 from '../../images/askidaCorba/events/kadir-gecesi/3.webp';
import KadirGecesiSlider4 from '../../images/askidaCorba/events/kadir-gecesi/4.webp';
import KadirGecesiSlider5 from '../../images/askidaCorba/events/kadir-gecesi/5.webp';

//Muharrem Ayı (Asure)
import AsureCard from '../../images/askidaCorba/events/asure/card.webp';
import AsureCover from '../../images/askidaCorba/events/asure/cover.webp';
import AsureCardSlider1 from '../../images/askidaCorba/events/asure/1.webp';
import AsureCardSlider2 from '../../images/askidaCorba/events/asure/2.webp';
import AsureCardSlider3 from '../../images/askidaCorba/events/asure/3.webp';
import AsureCardSlider4 from '../../images/askidaCorba/events/asure/4.webp';
import AsureCardSlider5 from '../../images/askidaCorba/events/asure/5.webp';

//Köfte
import KofteCard from '../../images/askidaCorba/events/kofte/card.webp';
import KofteCover from '../../images/askidaCorba/events/kofte/cover.webp';
import KofteSlider1 from '../../images/askidaCorba/events/kofte/1.webp';
import KofteSlider2 from '../../images/askidaCorba/events/kofte/2.webp';
import KofteSlider3 from '../../images/askidaCorba/events/kofte/3.webp';
import KofteSlider4 from '../../images/askidaCorba/events/kofte/4.webp';
import KofteSlider5 from '../../images/askidaCorba/events/kofte/5.webp';

//Pizza
import PizzaCard from '../../images/askidaCorba/events/pizza/card.webp';
import PizzaCover from '../../images/askidaCorba/events/pizza/cover.webp';
import PizzaSlider1 from '../../images/askidaCorba/events/pizza/1.webp';
import PizzaSlider2 from '../../images/askidaCorba/events/pizza/2.webp';
import PizzaSlider3 from '../../images/askidaCorba/events/pizza/3.webp';
import PizzaSlider4 from '../../images/askidaCorba/events/pizza/4.webp';
import PizzaSlider5 from '../../images/askidaCorba/events/pizza/5.webp';

//Hayvan Dostlarımız
import HayvanDostlarimizCard from '../../images/askidaCorba/events/hayvan-dostlarimiz/card.webp';
import HayvanDostlarimizCover from '../../images/askidaCorba/events/hayvan-dostlarimiz/cover.webp';
import HayvanDostlarimizSlider1 from '../../images/askidaCorba/events/hayvan-dostlarimiz/1.webp';
import HayvanDostlarimizSlider2 from '../../images/askidaCorba/events/hayvan-dostlarimiz/2.webp';
import HayvanDostlarimizSlider3 from '../../images/askidaCorba/events/hayvan-dostlarimiz/3.webp';
import HayvanDostlarimizSlider4 from '../../images/askidaCorba/events/hayvan-dostlarimiz/4.webp';
import HayvanDostlarimizSlider5 from '../../images/askidaCorba/events/hayvan-dostlarimiz/5.webp';

//Nugget
import NuggetCard from '../../images/askidaCorba/events/nugget/card.webp';
import NuggetCover from '../../images/askidaCorba/events/nugget/cover.webp';
import NuggetSlider1 from '../../images/askidaCorba/events/nugget/1.webp';
import NuggetSlider2 from '../../images/askidaCorba/events/nugget/2.webp';
import NuggetSlider3 from '../../images/askidaCorba/events/nugget/3.webp';
import NuggetSlider4 from '../../images/askidaCorba/events/nugget/4.webp';
import NuggetSlider5 from '../../images/askidaCorba/events/nugget/5.webp';

//Bir Hayalim Var
import BirHayalimizVarCard from '../../images/askidaCorba/events/bir-hayalimiz-var/card.webp';
import BirHayalimizVarCover from '../../images/askidaCorba/events/bir-hayalimiz-var/cover.webp';
import BirHayalimizVarSlider1 from '../../images/askidaCorba/events/bir-hayalimiz-var/1.webp';
import BirHayalimizVarSlider2 from '../../images/askidaCorba/events/bir-hayalimiz-var/2.webp';
import BirHayalimizVarSlider3 from '../../images/askidaCorba/events/bir-hayalimiz-var/3.webp';
import BirHayalimizVarSlider4 from '../../images/askidaCorba/events/bir-hayalimiz-var/4.webp';
import BirHayalimizVarSlider5 from '../../images/askidaCorba/events/bir-hayalimiz-var/5.webp';

/**Not : Lütfiye ablayla konuştuk.Etkinlikleri güncelliyeceğimiz için aşağıdakileri şu anda eklemedik.
 */
// //Sevgililer Günü
// import SevgililerGunuCard from '../../images/askidaCorba/events/sevgililer-gunu/card.webp';
// import SevgililerGunuCover from '../../images/askidaCorba/events/sevgililer-gunu/cover.webp';
// import SevgililerGunuSlider1 from '../../images/askidaCorba/events/sevgililer-gunu/1.webp';
// import SevgililerGunuSlider2 from '../../images/askidaCorba/events/sevgililer-gunu/2.webp';
// import SevgililerGunuSlider3 from '../../images/askidaCorba/events/sevgililer-gunu/3.webp';

// //Bir Koli de Sizden Olsun Mu
// import BirKolideSizdenOlsunMuCard from '../../images/askidaCorba/events/bir-koli-de-sizden-olsun-mu/card.webp';
// import BirKolideSizdenOlsunMuCover from '../../images/askidaCorba/events/bir-koli-de-sizden-olsun-mu/cover.webp';
// import BirKolideSizdenOlsunMuSlider1 from '../../images/askidaCorba/events/bir-koli-de-sizden-olsun-mu/1.webp';
// import BirKolideSizdenOlsunMuSlider2 from '../../images/askidaCorba/events/bir-koli-de-sizden-olsun-mu/2.webp';
// import BirKolideSizdenOlsunMuSlider3 from '../../images/askidaCorba/events/bir-koli-de-sizden-olsun-mu/3.webp';
// import BirKolideSizdenOlsunMuSlider4 from '../../images/askidaCorba/events/bir-koli-de-sizden-olsun-mu/4.webp';
// import BirKolideSizdenOlsunMuSlider5 from '../../images/askidaCorba/events/bir-koli-de-sizden-olsun-mu/5.webp';
const events = [
    {
        id: '1',
        tag: 'Hamburger Etkinliğimiz',
        date: '14',
        mounth: 'Ocak',
        title: "Çocuklarımızın hayalini kurduğu hamburgerleri kendilerine ulaştırdık.",
        description: ["1503 çocuğumuza emanetleriniz olan Hamburger Menülerini dağıttık. (Hamburger, ayran, balon ve şeker)", "Altındağ, Mamak, Keçiören, Yenimahalle ve Pursaklar ilçelerinde dağıtımlar yapıldı."],
        card: HamburgerCard,
        cover: HamburgerCover,
        images: [HamburgerSlider1, HamburgerSlider2, HamburgerSlider3, HamburgerSlider4, HamburgerSlider5]
    },
    {
        id: '2',
        tag: 'Yaş Pasta Etkinliğimiz',
        date: '3',
        mounth: 'Şubat',
        title: "Çocuklarımızın bizden talep etmesi üzerine, onlara pastalarını ulaştırdık.",
        description: ["300 çocuğumuza yaş pasta ikram ettik.", "Altındağ, Etlik, Keçiören, Mamak, Solfasol bölgelerinde dağıtımlar yapıldı."],
        card: YasPastaCard,
        cover: YasPastaCover, 
        images: [YasPastaSlider1, YasPastaSlider2, YasPastaSlider3, YasPastaSlider4, YasPastaSlider5]
    },
    {
        id: '3',
        tag: 'Pide Etkinliğimiz',
        date: '22',
        mounth: 'Şubat',
        title: "Çocuklarımızın bizden talep etmesi üzerine, onlara pidelerini ulaştırdık.",
        description: ["618 çocuğumuza Pide Menüsü dağıttık. (Pide + Ayran + Salata + Çikolata)", "Altındağ, Sincan, Keçiören, Mamak, Pursaklar ilçelerinde dağıtımlar yapıldı."],
        card: PideCard,
        cover: PideCover, 
        images: [PideSlider1, PideSlider2, PideSlider3, PideSlider4, PideSlider5]
    },
    {
        id: '4',
        tag: 'Miraç Kandili Etkinliğimiz',
        date: '10',
        mounth: 'Mart',
        title: "Miraç kandili vesilesi ile çocuklarımıza kandil simidi dağıttık.",
        description: ["2000 adet Kandil Simidi çocuklarımıza ulaştı.", "Solfasol, Akdere, Mutlu ve Zübeyde Hanım mahallelerinde dağıtımlar yapıldı."],
        card: MiracKandiliCard,
        cover: MiracKandiliCover,
        images: [MiracKandiliSlider1, MiracKandiliSlider2, MiracKandiliSlider3, MiracKandiliSlider4, MiracKandiliSlider5]
    },
    {
        id: '5',
        tag: 'Berat Kandili Etkinliğimiz',
        date: '27',
        mounth: 'Mart',
        title: "Kandil’de çocuklarımızın ağızları tatlansın diye kandil şekeri dağıtımı yaptık.",
        description: ["470 çocuğumuza kandil şekeri dağıttık.", "Zübeyde Hanım, Aşağı Solfasol, Yukarı Solfasol ve Sincan bölgelerinde dağıtımlar yapıldı."],
        card: BeratKandiliCard, 
        cover: BeratKandiliCover, 
        images: [BeratKandiliSlider1, BeratKandiliSlider2, BeratKandiliSlider3, BeratKandiliSlider4, BeratKandiliSlider5]
    },
    {
        id: '6',
        tag: 'Ramazan Bereketi Etkinliğimiz',
        date: '13',
        mounth: 'Nisan',
        title: "Ramazan Ayı'nın gelmesi ile birlikte zor durumda olan ihtiyaç sahibi ailelerimize gıda paketi dağıtımı yaptık.",
        description: ["1245 aileye gıda paketi dağıtımı yapıldı.", "Altındağ, Çankaya, Mamak, Keçiören, Sincan, Kazan, Yenimahalle ve Etimesgut ilçelerinde dağıtımlar yapıldı."],
        card: RamazanBereketiCard,
        cover: RamazanBereketiCover,
         images: [RamazanBereketiSlider1, RamazanBereketiSlider2, RamazanBereketiSlider3, RamazanBereketiSlider4, RamazanBereketiSlider5]
    },
    {
        id: '7',
        tag: '23 Nisan Etkinliğimiz',
        date: '23',
        mounth: 'Nisan',
        title: "Atamızın çocuklarımıza armağan ettiği bu güzel günde, çocuklarımıza hediye olarak elmalı şeker ve balon ulaştırdık.",
        description: ["250 çocuğumuza elmalı şeker ve balon ulaştırdık.", "Altındağ, Mamak, Keçiören , Pursaklar ilçelerinde dağıtımlar yapıldı."],
        card: CocukBayramiCard,
        cover: CocukBayramiCover,
        images: [CocukBayramiSlider1, CocukBayramiSlider2, CocukBayramiSlider3, CocukBayramiSlider4, CocukBayramiSlider5]
    },
    {
        id: '8',
        tag: 'Kadir Gecesi Etkinliğimiz',
        date: '8',
        mounth: 'Mayıs',
        title: 'Ramazan ayının en özel günlerinden biri olan Kadir Gecesi’nde ailelerimize sıcak yemek ikramı yapıldı.',
        description: ["500 kişilik yemek dağıtımı yapıldı.", "Şentepe, Anadolu mahallesi ve Kayalar Mahallesi'nde dağıtımlar yapıldı."],
        card: KadirGecesiCard,
        cover: KadirGecesiCover, 
        images: [KadirGecesiSlider1, KadirGecesiSlider2, KadirGecesiSlider3, KadirGecesiSlider4, KadirGecesiSlider5]
    },
    {
        id: '9',
        tag: 'Muharrem Ayı Etkinliğimiz',
        date: '19',
        mounth: 'Ağustos',
        title: 'Muharrem Ayında 1374 aşureyi çoçuklarımıza ikram ettik.',
        description: ["1374 kişilik aşure dağıtımı yapıldı.", "Altındağ, Yenimahalle, Mamak, Keçiören, Siteler, Temelli, Karşıyaka, Sincan ilçelerinde dağıtımlar yapıldı."],
        card: AsureCard,
        cover: AsureCover, 
        images: [AsureCardSlider1, AsureCardSlider2, AsureCardSlider3, AsureCardSlider4,AsureCardSlider5]
    },
    {
        id: '10',
        tag: 'Köfte-Oyuncak Etkinliğimiz',
        date: '18',
        mounth: 'Ekim',
        title: 'Bu etkinlikte amacımız çocuklarımızın tatmak istedikleri köfteleri ve çok sevdiği oyuncakları onlara hediye etmek olmuştu, ve amacımıza ulaştık.',
        description: ["998 çocuğumuza köfte+ayran ve oyuncak ulaştırdık.", "Altındağ, Yenimahalle, Mamak ve Keçiören ilçelerinde dağıtımlar yapıldı."],
        card: KofteCard,
        cover: KofteCover,
        images: [KofteSlider1, KofteSlider2, KofteSlider3, KofteSlider4, KofteSlider5]
    },
    {
        id: '11',
        tag: 'Pizza Etkinliğimiz',
        date: '8',
        mounth: 'Aralık',
        title: 'Miniklerimizin reklamlarda görüp tadını merak ettikleri pizzaları kendilerine ulaştırdık.',
        description: ["774 çocuğumuza Pizza + Ayran + Balon + Çikolata dağıttık.", "Sincan, Altındağ, Yenimahalle, Keçiören, Mamak ve Ayaş ilçelerinde dağıtımlar yapıldı."],
        card: PizzaCard,
        cover: PizzaCover,
        images: [PizzaSlider1, PizzaSlider2, PizzaSlider3, PizzaSlider4, PizzaSlider5]
    },
    {
        id: '12',
        tag: 'Hayvan Dostlarımız',
        date: '31',
        mounth: 'Aralık',
        title: 'Soğukta kalan ve bakıma muhtaç dostlarımızı düşünerek onlara Su, Yem ve Yemek dağıtma etkinlikleri yaptık.',
        description: ["200 dezavantajlı köpeğin bakımı ve barınmasını üstlenen 'Son Şans Özürlü Hayvanları Koruma ve Yaşatma Derneği'ne yemek desteği sağlandı.", "Kurtuluş Parkı’nda bulunan kuşlar için Yem ve Su desteği sağlandı, İnci anne hayvan barınağına yemek desteği sağlandı."],
        card: HayvanDostlarimizCard,
        cover: HayvanDostlarimizCover,
        images: [HayvanDostlarimizSlider1, HayvanDostlarimizSlider2, HayvanDostlarimizSlider3, HayvanDostlarimizSlider4, HayvanDostlarimizSlider5]
    },
    {
        id: '13',
        tag: 'Nugget Burger Etkinliğimiz',
        date: '21',
        mounth: 'Haziran',
        title: "Çocuklarımızın hayalini kurduğu nugget burgerleri kendilerine ulaştırdık.",
        description: ["518 çocuğumuza emanetleriniz olan Burger Menülerini dağıttık. (Nugget burger, içecek ve dondurma)", "Yenimahalle, Pursaklar ve Mamak ilçelerinde dağıtımlar yapıldı."],
        card: NuggetCard,
        cover: NuggetCover,
        images: [NuggetSlider1, NuggetSlider2, NuggetSlider3, NuggetSlider4, NuggetSlider5]
    },
    {
        id: '14',
        tag: 'Bir Hayalimiz Var Etkinliğimiz',
        date: '19',
        mounth: 'Kasım',
        title: "Bir hayalimiz var dedik. Bu hayalimize ortak olan tüm dostlarımıza teşekkür ederiz.",
        description: ["Askıda Çorba"],
        card: BirHayalimizVarCard,
        cover: BirHayalimizVarCover,
        images: [BirHayalimizVarSlider1, BirHayalimizVarSlider2, BirHayalimizVarSlider3, BirHayalimizVarSlider4, BirHayalimizVarSlider5]
    },
    // {
    //     id: '15',
    //     tag: 'Sevgililer Günü',
    //     date: '14',
    //     mounth: 'Şubat',
    //     title: "",
    //     description: ["Askıda Çorba"],
    //     card: SevgililerGunuCard,
    //     cover: SevgililerGunuCover,
    //     images: [SevgililerGunuSlider1, SevgililerGunuSlider2, SevgililerGunuSlider3]
    // },
    // {
    //     id: '16',
    //     tag: 'Bir koli de sizden olsun mu',
    //     date: '',
    //     mounth: '',
    //     title: "",
    //     description: ["Askıda Çorba"],
    //     card: BirKolideSizdenOlsunMuCard,
    //     cover: BirKolideSizdenOlsunMuCover,
    //     images: [BirKolideSizdenOlsunMuSlider1, BirKolideSizdenOlsunMuSlider2, BirKolideSizdenOlsunMuSlider3,BirKolideSizdenOlsunMuSlider4,BirKolideSizdenOlsunMuSlider5]
    // },
]

export default events;