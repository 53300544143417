import React from 'react'
import './style.css'
import VideoModal from '../ModalVideo'
import { Link } from 'react-router-dom'
import { FacebookProvider, Page } from 'react-facebook';
import InstagramEmbed from 'react-instagram-embed';
const Hero = (props) => {

    return (
        // <section className="hero hero-style-2">
        //     <div className="hero-slider">
        //         <div className="slide">
        //             <div className="container">
        //                 <div className="row">
        //                     <div className="col col-lg-6 slide-caption">
        //                         <div className="slide-title" style={{ marginTop: 20 }}>
        //                             <h4 style={{ color: "#666666" }}>Bir sıcak çorba da sizden <span>olsun mu?</span></h4>
        //                         </div>
        //                         <div className="slide-subtitle">
        //                             {/* <p>High Quality Charity Theme in Envato Market.</p> */}
        //                             {/* <p>You Can Satisfied Yourself By Helping.</p> */}
        //                         </div>
        //                         <div className="btns">
        //                             <Link to="/donate" className="theme-btn">Askıya Çorba Bırak</Link>
        //                             <ul>
        //                                 <li className="video-holder">
        //                                     <VideoModal />
        //                                 </li>
        //                                 <li className="video-text" style={{ color: "#666666" }}>
        //                                     Videomuzu İzleyin
        //                                 </li>
        //                             </ul>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //             <div className="right-vec">
        //             </div>
        //         </div>
        //     </div>
        //     <div className="hero-shape">
        //         <img src={hero1} alt="" />
        //     </div>
        // </section>
        <section>
            <div className="hero-slider">
                <div className="slide">
                    <div className="container">
                        <div className="row">
                            <div className="col col-lg-6 slide-caption">
                                {/* <div className="slide-title" style={{ marginTop: 40 }}>
                                    <h4 style={{ color: "#666666" }}>Bir sıcak çorba da sizden <span>olsun mu?</span></h4>
                                </div> */}
                                <div className="slide-subtitle">
                                    {/* <p>High Quality Charity Theme in Envato Market.</p> */}
                                    {/* <p>You Can Satisfied Yourself By Helping.</p> */}
                                </div>
                                <div className="btns">
                                    <Link to="/donate" className="theme-btn">Askıya Çorba Bırak</Link>
                                    <ul>
                                        <li className="video-holder">
                                            <VideoModal />
                                        </li>
                                        <li className="video-text" style={{ color: "#666666" }}>
                                            Videomuzu İzleyin
                                        </li>
                                    </ul>
                                </div>
                                <div className="slide-title" style={{ marginTop: 40 }}>
                                    <h4 style={{ color: "#666666",fontSize : 40 }}>Bir sıcak çorba da sizden <span style={{color : "#08cc7f"}}>olsun mu?</span></h4>
                                </div>
                                <div>
                                    <p>Küçücük yardımlardan kocaman hayallere...</p>
                                </div>
                            </div>

                            <div className="col col-lg-6 slide-caption" style={{ zIndex: 1 }}>
                                <div style={{ marginTop: 40, marginBottom: 10 }}>
                                    <FacebookProvider appId="https://www.facebook.com/Askıda-Çorba-101122171503772/">
                                        <Page href="https://www.facebook.com/Askıda-Çorba-101122171503772/" tabs="timeline" width="500px" height="300px" />
                                    </FacebookProvider>
                                    {/* <InstagramEmbed
                                        url='https://www.facebook.com/Askıda-Çorba-101122171503772/'
                                        clientAccessToken='101122171503772'
                                        maxWidth={320}
                                        hideCaption={false}
                                        containerTagName='div'
                                        protocol=''
                                        injectScript
                                        onLoading={() => { }}
                                        onSuccess={() => { }}
                                        onAfterRender={() => { }}
                                        onFailure={() => { }}
                                    /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero;