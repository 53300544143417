import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../images/logo-2.svg'
import Newsletter from '../Newsletter'
import n1 from '../../images/footer/img-1.jpg'
import n2 from '../../images/footer/img-2.jpg'
import './style.css'

const Footer = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <footer className="wpo-site-footer">
            <Newsletter />
            <div className="wpo-upper-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="widget about-widget">
                                <div className="logo widget-title">
                                    <img src={Logo} alt="" width={100} />
                                </div>
                                <p>Bizi takip et</p>

                                <div className="share">
                                    <ul>
                                        <li><a href="https://www.facebook.com/pages/category/Charity-Organization/Ask%C4%B1da-%C3%87orba-101122171503772/" target="_blank"><i className="ti-facebook"></i></a></li>
                                        <li><a href="https://twitter.com/askida_corba" target="_blank"><i className="ti-twitter-alt"></i></a></li>
                                        <li><a href="https://www.instagram.com/askida_corba" target="_blank"><i className="ti-instagram"></i></a></li>
                                        <li><a href="https://www.youtube.com/channel/UCteb17Za-vvxA7x6NPd1frQ" target="_blank"><i className="ti-youtube"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            {/* <div className="widget link-widget resource-widget">
                            <div className="widget-title">
                                <h3>Top News</h3>
                            </div>
                            <div className="news-wrap">
                                <div className="news-img">
                                    <img src={n1} alt=""/>
                                </div>
                                <div className="news-text">
                                    <h3><Link onClick={ClickHandler} to="/blog">Education for all poor children</Link></h3>
                                    <span>12 Nov, 2020</span>
                                </div>
                            </div>
                            <div className="news-wrap">
                                <div className="news-img">
                                    <img src={n2} alt=""/>
                                </div>
                                <div className="news-text">
                                    <h3><Link onClick={ClickHandler} to="/blog">Education for all poor children</Link></h3>
                                    <span>12 Nov, 2020</span>
                                </div>
                            </div>
                        </div> */}
                        </div>
                        <div className="col col-lg-2 col-md-6 col-sm-12 col-12">
                            <div className="widget link-widget">
                                <div className="widget-title">
                                    <h3>Kurumsal</h3>
                                </div>
                                <ul>
                                    {/* <li><Link onClick={ClickHandler} to="/about">Hakkımızda</Link></li> */}
                                    <li><Link onClick={ClickHandler} to="/about">Hakkımızda</Link></li>
                                    <li><Link onClick={ClickHandler} to="/frequentlyAskedQuestions">Sıkça Sorulan Sorular</Link></li>
                                    <li><Link onClick={ClickHandler} to="/contact">İletişim</Link></li>
                                    <li><Link onClick={ClickHandler} to="/preliminary-information-form">Ön Bilgilendirme Formu</Link></li>
                                    <li><Link onClick={ClickHandler} to="/distant-sales-contract">Mesafeli Satış Sözleşmesi</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col col-lg-3 offset-lg-1 col-md-6 col-sm-12 col-12">
                            <div className="widget market-widget wpo-service-link-widget">
                                <div className="widget-title">
                                    <h3>İletişim </h3>
                                </div>
                                {/* <p>online store with lots of cool and exclusive wpo-features</p> */}
                                <div className="contact-ft">
                                    <ul>
                                        <li><a href="https://goo.gl/maps/8MGvjDHSLmQmyRuL9" target="_blank" style={{ color: "white" }}><i className="fi flaticon-pin"></i>Erzurum, Dumlupınar Cd. 8 A, 06590 Çankaya/Ankara</a></li>
                                        <li><i className="fi flaticon-call"></i><a href="tel:+90 533 407 58 85">0533 407 58 85</a></li>
                                        <li><i className="fi flaticon-envelope"></i>info@askidacorba.org</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wpo-lower-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-xs-12">
                            <p className="copyright">Askıda Çorba Gıda Restoran İşletmeciliği Ve Ticaret A.Ş.</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;