import React, { Component } from 'react';
import { Card, CardHeader, CardTitle, CardBody, CardFooter, Form, FormGroup, Input, Label } from 'reactstrap';
import axios from 'axios';
import { API_ENDPOINTS } from '../../models/Constants'
import { withRouter} from "react-router-dom";
import {toast} from "react-toastify";
class SoupOperations extends Component {
    _isMounted = false;
    state = {
        price: 0,
    }

    onChangeHandler = event => {
        let object = {};
        object[event.target.name] = event.target.value;
        this.setState(object);
    }

    fetchData = () => {
        if (!this._isMounted) {
            return;
        }
        axios
            .get(API_ENDPOINTS.GET_SOUP_PRICE)
            .then(response => {
                this.setState({
                    price: response.data
                })
            })
            .catch(err => {

            });
    }

    submitData = () => {
        const { price } = this.state;
        if (price <= 0) {
            toast.error('Lütfen Çorba Fiyatını Giriniz');
            return;
        }

        axios
            .post(API_ENDPOINTS.UPDATE_SOUP, { name: 'Çorba', price: parseInt(price) })
            .then(response => {
                this.props.history.push("/donation-status")
            })
            .catch(err => {
                toast.error('Hata Oluştu');
            });

    }

    componentDidMount() {
        this._isMounted = true;
        this.fetchData();
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    };

    render() {
        const { price } = this.state;
        return (
            <Card>
                <CardHeader>
                    <CardTitle tag="h4">Çorba Detayı</CardTitle>
                </CardHeader>
                <CardBody>
                    <Form action="#" method="#">
                        <Label>Çorba Fiyatı Giriniz</Label>
                        <FormGroup>
                            <Input name="price" type="number" value={price} onChange={this.onChangeHandler} placeholder="Çorba Fiyatı Giriniz" />
                        </FormGroup>
                    </Form>
                </CardBody>
                <CardFooter>
                    <div className="submit-area">
                        <button type="submit" className="theme-btn submit-btn" onClick={this.submitData}>Güncelle</button>
                    </div>
                </CardFooter>
            </Card>
        )
    }
}
export default withRouter(SoupOperations);