import React from 'react'
import { Link } from 'react-router-dom'
import { IMAGE_ENDPOINTS } from '../../models/Constants';
import '../event/style.css';
import EventData from './eventData';

const image = (path) => {
    let src = "";
    if (path) {
        src = path;
    }
    else {
        src = IMAGE_ENDPOINTS.LOGO;
    }
    return <img src={src} alt="" width={200} height={200} />;
    // return <img src={src} alt="" width={100} height={150} />;
}

const EventSection = (props) => {
    return (
        <div className="wpo-event-area wpo-event-area2 section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="wpo-section-title">
                            <span>Etkinliklerimiz</span>
                            {/* <h2>Upcoming Events</h2> */}
                        </div>
                    </div>
                </div>
                <div className="row">
                    {
                        EventData.map(event => {
                            return (
                                <div className="col-lg-3 col-md-6 col-sm-12 col-12 custom-grid">
                                    <Link to={'/event-details/' + event.id}>
                                        <div className="wpo-event-item" style={{ border: "1px solid #08cc7f"/* , minHeight: 270, maxHeight: 270 */ }}>
                                            <div className="wpo-event-img">
                                                {image(event.card)}
                                                {/* <div className="thumb-text">
                                                    <span>{event.date}</span>
                                                    <span>{event.mounth}</span>
                                                </div> */}
                                            </div>
                                            <div className="wpo-event-text" /* style={{ border: "1px solid #08cc7f", minHeight: 240, maxHeight: 120 }} */>
                                                <h2 style={{ fontSize: 12 }}>{event.tag}</h2>
                                                {/* <ul>
                                                <li><i className="fa fa-clock-o" aria-hidden="true"></i>8.00 - 5.00</li>
                                                <li><i className="fi flaticon-pin"></i>Newyork City</li>
                                            </ul> */}
                                                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p> */}
                                                <Link to={'/event-details/' + event.id}>Daha Fazla...</Link>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default EventSection;