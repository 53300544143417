import React, { Fragment, Component } from 'react';
import Header from '../../components/header';
// import PageTitle from '../../components/pagetitle'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import { Link } from 'react-router-dom'
import { IMAGE_ENDPOINTS } from '../../models/Constants';
import { Button } from 'reactstrap';
class PaymentError extends Component {
    render() {
        return (
            <div className="wpo-donation-page-area section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="wpo-donations-details">
                                <div className="wpo-cta-text">
                                    <span>
                                        <img src={IMAGE_ENDPOINTS.DONATION.Error} style={{ marginLeft: "auto", marginRight: "auto", display: "block" }} alt="" />
                                    </span>
                                    <span>Sipariş Sırasında Hata Oluştu!</span><br />
                                    <span>Tekrar denemek ister misiniz?</span>
                                    <div className="btns">
                                        <Link to="/donate">
                                            <Button outline color="secondary" style={{ marginTop: 5 }}>Askıya Çorba Bırak Sayfasına Git</Button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const PaymentErrorPage = () => {
    return (
        <Fragment>
            <Header />
            {/* <PageTitle pageTitle={'Askı Durumu'} pagesub={'Askı'} /> */}
            <PaymentError />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
}

export default PaymentErrorPage;