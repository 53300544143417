import React, { Fragment, Component } from 'react';
import Header from '../../components/header';
// import PageTitle from '../../components/pagetitle'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import { Link } from 'react-router-dom'
import { IMAGE_ENDPOINTS } from '../../models/Constants';
import { Button } from 'reactstrap';
class PaymentSuccess extends Component {
    render() {
        return (
            <div className="wpo-donation-page-area section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="wpo-donations-details">
                                <div className="wpo-cta-text">
                                    <span>
                                        <img src={IMAGE_ENDPOINTS.DONATION.Success} style={{ marginLeft: "auto", marginRight: "auto", display: "block" }} alt="" />
                                    </span>
                                    <span>Askıya bıraktığınız çorba için teşekkür ederiz.</span><br />
                                    <span>Askı durumunu detay sayfasında görüntüleyebilirsiniz.</span>
                                    <div className="btns">
                                        <Link to="/donation-status">
                                            <Button outline color="secondary" style={{ marginTop: 5 }}>Askı Durumu Sayfasına Git</Button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const PaymentSuccessPage = () => {
    return (
        <Fragment>
            <Header />
            {/* <PageTitle pageTitle={'Askı Durumu'} pagesub={'Askı'} /> */}
            <PaymentSuccess />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
}

export default PaymentSuccessPage;