import React, { Component } from 'react';
import { Card, CardHeader, CardTitle, Table, Button, Label, FormGroup, Col, Row } from 'reactstrap';
import ReactDateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import moment from 'moment';
import { toast } from 'react-toastify';
import axios from 'axios';
import { API_ENDPOINTS } from '../../models/Constants';
import GlobalHelpers from '../../helpers/GlobalHelpers';
import Workbook from 'react-excel-workbook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
class StockReports extends Component {
    _isMounted = false;
    state = {
        startDate: moment().add(-1, "month"),
        endDate: moment(),
        dataList: [],
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onClickHandler = () => {
        const { startDate, endDate } = this.state;
        axios
            .get(API_ENDPOINTS.GET_STOCK_REPORTS, {
                params: {
                    startDate: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
                    endDate: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
                }
            })
            .then(response => {
                if (response.data.length === 0) {
                    toast.warning('Veri Bulunamadı')
                    this.setState({
                        dataList: []
                    })
                } else {
                    this.setState({
                        dataList: response.data
                    })
                }
            })
    }

    render() {
        const { startDate, endDate, dataList } = this.state;
        return (
            <Card>
                <CardHeader>
                    <CardTitle tag="h4">Rapor Al</CardTitle>
                </CardHeader>
                <Row>
                    <Col lg="3" md="3" sm="3">
                        <Label>Başlangıç Tarihi</Label>
                        <FormGroup>
                            <ReactDateTime
                                inputProps={{
                                    className: "form-control",
                                    placeholder: "Date Picker Here"
                                }}
                                value={startDate.toDate()}
                                dateFormat="YYYY-MM-DD"
                                timeFormat={false}
                                closeOnSelect
                                onChange={value => { this.setState({ startDate: moment(value) }) }}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg="3" md="3" sm="3">
                        <Label>Bitiş Tarihi</Label>
                        <FormGroup>
                            <ReactDateTime
                                inputProps={{
                                    className: "form-control",
                                    placeholder: "Bitiş Tarihini Giriniz"
                                }}
                                value={endDate.toDate()}
                                dateFormat="YYYY-MM-DD"
                                timeFormat={false}
                                closeOnSelect
                                onChange={value => { this.setState({ endDate: moment(value) }) }}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg="3" md="3" sm="3">
                        <Label></Label>
                        <FormGroup>
                            <Button outline color="secondary" style={{ marginTop: 7 }} onClick={() => this.onClickHandler()}>Ara</Button>
                        </FormGroup>
                    </Col>
                </Row>
                {
                    dataList.length > 0 ? (
                        <>
                            <Workbook filename={startDate + " - " + endDate + " Tarihleri Arasında Bağış Yapanların Listesi.xlsx"}
                                element={<Button outline color="secondary">
                                    Excel'e Aktar
                                    {/* <FontAwesomeIcon icon={faFileExcel} /> */}
                                </Button>}>
                                <Workbook.Sheet data={dataList} name="Sheet 1">
                                    <Workbook.Column label="Adet" value={row => row.quantity} />
                                    <Workbook.Column label="Açıklama" value={row => row.description} />
                                    <Workbook.Column label="Tarih" value={row => GlobalHelpers.formatDate(row.date)} />
                                </Workbook.Sheet>
                            </Workbook>
                            {/* <Button outline color="secondary" onClick={() => this.onClickHandler()}>Excel'e Aktar</Button> */}
                            <Table className="table-hover">
                                <thead>
                                    <tr>
                                        <th>Adet</th>
                                        <th>Açıklama</th>
                                        <th>Tarih</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        dataList.map(data => {
                                            return (
                                                <tr key={Math.random()}>
                                                    <td>{data.quantity}</td>
                                                    <td>{data.description}</td>
                                                    <td>{GlobalHelpers.formatDate(data.date)}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </>
                    ) : ''
                }
            </Card>
        )
    }
}
export default StockReports;