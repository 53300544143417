import React, {Fragment} from 'react';
import Header from '../../components/header'
// import PageTitle from '../../components/pagetitle'
import EventSection from '../../components/event'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'


const Index =() => {
    return(
        <Fragment>
            <Header/>
            {/* <PageTitle pageTitle={'Our Event'} pagesub={'Event'}/>  */}
            <EventSection/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default Index;
