import React, { Component } from 'react';
import axios from 'axios';
import Loading from '../../components/Loading/index';
import { API_ENDPOINTS } from '../../models/Constants';
import { defaultTake } from '../../helpers/CommonHelper';

class LastDonations extends Component {
    _isMounted = false;
    state = {
        dataList: [],
        loading: false
    }

    fetchData = () => {
        if (!this._isMounted) {
            return;
        }
        this.setState({ loading: true });
        const take = this.props.take ? this.props.take : defaultTake; //If set take value, return get data limited param else default set limit 20
        axios
            .get(API_ENDPOINTS.GET_LAST_DONATIONS + take)
            .then(response => {
                this.setState({
                    dataList: response.data,
                    loading: false
                })
            })
            .catch(err => {
                this.setState({ loading: false });
            });
    }

    componentDidMount() {
        this._isMounted = true;
        this.fetchData();
    }
    componentWillUnmount = () => {
        this._isMounted = false;
    };

    getDetail = (row) => {
        const description = row.description;
        const userName = row.userName;
        const hideDonatorsDetails = row.hideDonatorsDetails;
        if (hideDonatorsDetails) {
            return <span className="date">{description}</span>
        } else {
            if (userName && userName !== "lütfiye" && userName !== "birol") {
                return <span className="date">{description + "[" + userName + "]"}</span>
            } else {
                return <span className="date">{description}</span>
            }
        }
    }

    render() {
        const { loading, dataList } = this.state;
        return (
            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                <div className="widget recent-post-widget">
                    <h3 style={{ color: "#08cc7f" }}>En Son Askıya Bırakılanlar ({defaultTake})</h3>
                    <div className={loading ? "posts text-center" : "posts"}>
                        {
                            loading ? (
                                <Loading />
                            ) : (
                                dataList.map(row => {
                                    return (
                                        <div className="post">
                                            <div className="img-holder">
                                                <img src="" alt="" />
                                            </div>
                                            <div className="details">
                                                <h4>{row.quantity} Adet</h4>
                                                {/* <span className="date">{row.description}</span> */}
                                                {this.getDetail(row)}
                                            </div>
                                        </div>
                                    )
                                })
                            )
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default LastDonations;