import React, { Component } from 'react';
import { Card, CardHeader, CardTitle, Table,Button } from 'reactstrap';
import axios from 'axios';
import { API_ENDPOINTS } from '../../models/Constants'
import { toast } from "react-toastify";
import GlobalHelpers from '../../helpers/GlobalHelpers';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
class StockHistories extends Component {
    _isMounted = false;
    state = {
        dataList: [],
    }

    componentDidMount() {
        this._isMounted = true;
        this.fetchData();
    }
    componentWillUnmount = () => {
        this._isMounted = false;
    };

    fetchData = () => {
        if (!this._isMounted) {
            return;
        }

        axios
            .get(API_ENDPOINTS.GET_STOCK_HISTORIES)
            .then(response => {
                this.setState({
                    dataList: response.data,
                })
            })
            .catch(err => {
                toast.error("Hata Oluştu");
            });
    }

    render() {
        const { dataList } = this.state;
        return (
            <Card>
                <CardHeader>
                    <CardTitle tag="h4">Askı İşlem Geçmişi</CardTitle>
                    <span>Askıdan Dağıtılan İşlemler <b>Gri</b> Renk'de gösterilir.</span><br/>
                    <span>İnternet Üzerinden Yapılan Bağışlar <b>Turuncu</b> Renk'de gösterilir.</span><br/>
                </CardHeader>
                <Table className="table-hover">
                    <thead>
                        <tr>
                            <th>Adet</th>
                            <th>Fiyat</th>
                            {/* <th>Toplam Fiyat</th> */}
                            <th>Durum</th>
                            <th>Açıklama</th>
                            <th>Tarih</th>
                            <th>Kullanıcı</th>
                            {/* <th>Kullanıcı Adı</th> */}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            dataList.map(data => {
                                return (
                                    <tr key={Math.random()} style={{ 
                                        backgroundColor: data.quantity <= 0 
                                            ? "grey" : data.paymentMethod ? "orange" : "" 
                                        }}>
                                        <td>{data.quantity}</td>
                                        <td>{data.totalPrice}</td>
                                        <td>{data.quantity < 0 ? "Askıdan Dagıtılan" : "Askıya Bırakılan"}</td>
                                        <td>{data.description}</td>
                                        <td>{GlobalHelpers.formatDate(data.date)}</td>
                                        <td>{data.userName}</td>
                                        <td>
                                            <Link
                                                to={{
                                                    // pathname: '/',
                                                    search: (contactId => {
                                                        let qs = queryString.parse(window.location.search);
                                                        qs.contactId = contactId;
                                                        return queryString.stringify(qs);
                                                    })(data.id)
                                                }}
                                            >
                                            {
                                                data.userName ? (
                                                    <Button color="success" size="sm" style={{ marginTop: 5 }}><i className="fa fa-pencil" /></Button>
                                                ) : null
                                            }
                                            </Link>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </Card>
        )
    }
}

export default StockHistories;